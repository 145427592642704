import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState, useRef } from 'react';
import Slider from "react-slick";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Fade } from '@mui/material';
import ViewState from '../../state/view-state';
import Layout from '../Layout';
import Picture from '../component/Picture';
import ShopList from './shop_list'
import ShareDialog from './share_dialog';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Reply';
import { data, dataSort, dataTitle } from './useData';

// 夢想のまち画像を基準に他の画像の高さを決める
const heightBaseImg = data[0].key;

// ブラウザの幅変更で画像の大きさを変える
const calculateMinHeight = (refSlider) => {
  if (refSlider.current) {
    const nodeList = document.querySelectorAll(`img.slick-image[alt="${heightBaseImg}"]`);
    if (nodeList.length) {
      const heightBaseNode = nodeList[0];
      if (heightBaseNode.naturalHeight) {
        const multify = heightBaseNode.naturalHeight / heightBaseNode.naturalWidth;

        return Math.round(heightBaseNode.width * multify);
      }
    }
  }

  return;
  /** 全画像のminを取得するならば↓ */
  // const images = refSlider.current.querySelectorAll('.slick-image');
  // const heights = Array.from(images).map((img) => img.clientHeight);
  // const minHeight = Math.min(...heights);
  // setMinHeight(minHeight);
};

const getClientX = (event) => {
  return event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
}

export default function Story(props) {

  const refSlider = useRef(null);
  const refShopSlider = useRef(null);
  const [step, setStep] = useState(1);
  const [fade, setFade] = useState(false);
  const refLoadImg = useRef({});
  const [loadImgCount, setLoadImgCount] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [isHeight, setIsHeight] = useState(false);
  const [dialog, setDialog] = useState(false);
  const title = props.match.params.title;
  const refTitleName = useRef(dataTitle[title]);

  // レスポンシブ
  const matches = useMediaQuery('(min-width:600px)', { noSsr: true });
  let linksMarginTop;
  if (matches) {
    linksMarginTop = "60px";
  } else {
    linksMarginTop = "22px";
  }

  const handleDialogClose = () => {
    setDialog(false);
  }

  // メッセージダイアログ
  const shareDialog = (dialog === true) ? (
    <ShareDialog
      shareUrl={window.location.href}
      shareText={refTitleName.current}
      open={dialog} onClose={handleDialogClose} />
  ) : null;

  const settings = {
    dots: true,
    beforeChange: (oldIndex, newIndex) => {
      refShopSlider.current.slickGoTo(newIndex);
    },
    afterChange: (index) => {
      window.history.replaceState(null, '', `/story/${data[index].key}`);
      refTitleName.current = dataTitle[data[index].key];
    }
  };

  const setHeight = () => {
    setFade(false);
    ViewState.closeCurtain();

    setTimeout(() => {
      const height = calculateMinHeight(refSlider);
      if (height) {
        setMinHeight(height);
      }
      setFade(true);
      ViewState.openCurtain();
    }, 200);
  }

  // 作品画像をクリックしたら次の画像へ
  const arrowClick = (event) => {
    if (event.target.classList.contains('slick-image')) {
      const centerX = window.innerWidth / 2;
      const clickedX = getClientX(event);

      if (clickedX <= centerX) {
        refSlider.current.slickPrev();
      } else {
        refSlider.current.slickNext();
      }
    }
  }

  // 初期タイトル設定
  useEffect(() => {
    if (title in dataSort) {
      refSlider.current.slickGoTo(dataSort[title]);
    }
  }, [title, refSlider]);

  // イベントリスナー設定
  useEffect(() => {
    window.addEventListener('resize', setHeight);
    document.addEventListener('click', arrowClick);

    // 最大6秒で読み込み終了。間に合わなければ画像が中途半端に表示される。
    setTimeout(() => {
      setFade(true);
    }, 6000);

    return () => {
      window.removeEventListener('resize', setHeight);
      document.removeEventListener('click', arrowClick);
    };
  }, []);

  useEffect(() => {
    // 共有するタイトルを設定
    if (title in dataTitle) {
      refTitleName.current = dataTitle[title];
    } else {
      // storyのパスがない場合、最初の作品にする
      window.history.replaceState(null, '', `/story/${data[0].key}`);
      refTitleName.current = dataTitle[data[0].key];
    }
  }, [title]);

  // 高さ設定
  useEffect(() => {
    if (fade === true && !(minHeight > 0)) {
      setTimeout(() => {
        setHeight();
        setIsHeight(!isHeight);
      }, 50);
    }

  }, [fade, isHeight, minHeight]);

  // 画像読み込み待ち
  useEffect(() => {
    if (loadImgCount >= data.length) {
      setFade(true);
    }
  }, [loadImgCount]);

  // 初期処理
  useEffect(() => {
    if (step === 1) {
      ViewState.setTitle('Story');
      setStep(2);

    } else if (step === 2 && fade === true && minHeight > 0) {
      setTimeout(() => {
        ViewState.openCurtain();
      }, 0);
    }
  }, [step, fade, minHeight]);

  return (
    <Layout loading={!fade}>
      <Fade in={fade} timeout={700}>
        <Box>
          <div style={{ position: 'relative' }}>
            <Slider ref={refSlider} {...settings}
              style={{ maxWidth: '100vw', width: '100%', overflow: 'hidden' }}>
              {data.map(story => {
                return (
                  <Box key={story.key}>
                    {/* <a href={story.url} target="_blank" rel="noopener noreferrer"> */}
                    <Picture
                      name={story.key}
                      style={{
                        display: 'block',
                        objectFit: 'cover',
                        objectPosition: 'center bottom',
                        width: '100%',
                        // height: '100%',
                        height: `${minHeight}px`,
                      }}
                      onLoad={() => {
                        refLoadImg.current[story.key] = true;
                        setLoadImgCount(Object.keys(refLoadImg.current).length);
                      }}
                      className="slick-image"
                    />
                    {/* </a> */}
                  </Box>
                )
              })}
            </Slider>
            <Box
              style={{
                position: 'absolute',
                right: '0px',
                bottom: '-8px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  margin: '0px 10px 0px 0px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setDialog(true);
                }}
              >
                <IconButton style={{
                  color: 'rgb(225, 225, 225)'
                }}>
                  <ShareIcon style={{ transform: 'scaleX(-1)' }} />
                  <span style={{
                    fontSize: '0.8rem',
                    marginLeft: '2px',
                  }}>共有</span>
                </IconButton>

              </div>
            </Box>
          </div>
          <Box
            style={{ marginTop: linksMarginTop }}
          >
            <ShopList ref={refShopSlider} refParentSlider={refSlider}></ShopList>
          </Box>
          {shareDialog}
        </Box>
      </Fade>
    </Layout>
  )
}