import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Grid } from '@mui/material';
import { HamburgerSpin } from 'react-animated-burgers'
import ViewState from '../state/view-state';

const links = [
  { key: 'Portal', to: '/' },
  { key: 'Works', to: '/works' },
  { key: 'Story', to: '/story' },
  { key: 'Goods', to: '/goods' },
  { key: 'Contact', to: '/contact' }
];

// image-rendering: -webkit-optimize-contrast;

const style = {
  grid: {
    margin: '20px 40px 20px 32px',
    cursor: 'pointer',
    height: '18px'
  },
  link: {
    textDecoration: 'none',
    color: 'rgb(201,201,201)'
  }
};

export default function Menu(props) {
  const { onClick } = props;
  const [, setMenuOpen] = ViewState.get('menuOpen');
  const [close, setClose] = React.useState(false);

  setTimeout(() => {
    setClose(true);
  }, 1)

  return (
    <Container style={{
      padding: 0,
      backgroundColor: 'rgb(28, 28, 28)',
      color: 'rgb(201,201,201)',
      height: '100%',
    }}>
      <Grid
        container
        direction="column"
        spacing={0}
      >
        <Grid item style={{
          margin: '20px 10px 10px 24px',
          cursor: 'pointer',
        }}>
          <HamburgerSpin
            buttonWidth={20}
            isActive={close}
            toggleButton={() => { setMenuOpen(false) }}
            barColor="white"
          />
          {/* <MenuIcon /> */}
        </Grid>
        {links.map(link => {
          return (
            <Grid item key={link.key} style={style.grid}>
              <Link to={link.to} style={style.link} onClick={onClick(false)}>
                <span>{link.key}</span>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  )
}