import React from 'react';
import ViewState from '../../state/view-state';

export default function Picture(props) {
    const { name, ...others } = props;

    return (
        <picture>
            <source srcSet={`${process.env.REACT_APP_IMAGE_URL}/${name}.webp?v=${ViewState.getVersion()}`} type="image/webp" />
            <img
                alt={name}
                draggable="false"
                src={`${process.env.REACT_APP_IMAGE_URL}/${name}.png?v=${ViewState.getVersion()}`}
                {...others}
            />
        </picture>
    )
}