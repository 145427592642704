import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Container, Box, Fade, Grid,
  Typography,
  Card, CardActionArea, CardContent, CardMedia
} from '@mui/material';
import ViewState from '../../state/view-state';
import KvModel from '../../model/kv-model';
import Layout from '../Layout';
import Picture from '../component/Picture';

const useStyles = makeStyles({
  root: {
    minWidth: 295,
    maxWidth: 345,
    boxShadow: 'none',
    border: 'solid 1px',
    borderColor: 'rgba(0,0,0, 0.13)',
    backgroundColor: 'rgb(28, 28, 28)',
    color: 'rgb(225, 225, 225)',
  },
  media: {
    width: '100%',
  },
  content: {
  }
});

const cardCount = 1;
const tempStyle = {
  musou1: { height: "100%" }
};

function Good(props) {
  const classes = useStyles();

  const { countUp, imageId, title, description, href } = props;

  let addStyle = {};
  if (imageId in tempStyle) {
    addStyle = {
      style: tempStyle[imageId]
    }
  }

  // 
  let descriptionHtml = null;
  if (description !== '_') {
    descriptionHtml = (
      <Typography
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          "WebkitLineClamp": '2',
          "WebkitBoxOrient": 'vertical',
          "WebkitAppearance": 'none',
          display: 'WebkitBox',
          color: 'rgb(255, 255, 255)'
        }}
        variant="body2" component="p">
        {description}
      </Typography>
    );
  }

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia className={classes.media} {...addStyle}>
            <Picture
              name={imageId}
              style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%' }}
              onLoad={() => countUp()}
            />
          </CardMedia>
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h5" component="p">
              {title}
            </Typography>
            {descriptionHtml}
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  )
}

export default function Goods() {

  const [fade, setFade] = useState(false);
  const [cards, setCards] = useState([]);
  const [count, setCount] = useState(0);

  const countUp = () => {
    if ((count + 1) >= cardCount) {
      ViewState.openCurtain();
      setFade(true);
    }
    setCount(count + 1)
  }

  useEffect(() => {
    ViewState.setTitle('Goods');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await KvModel.get('goods');
      setCards(JSON.parse(result));
    };

    fetchData();
  }, []);

  return (
    <Layout loading={!fade}>
      <Fade in={fade} timeout={700}>
        <Box>
          <Container style={{
            paddingTop: 50,
            paddingLeft: 40,
            paddingRight: 40,
          }}>
            <Grid justifyContent="space-around" direction="column" alignItems="center" container spacing={5}>
              {cards.map((card, index) => {
                return (
                  <Grid item key={index}>
                    <Good
                      countUp={countUp}
                      title={card.title}
                      description={card.text}
                      imageId={card.imageId}
                      href={card.link}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Container>
        </Box>
      </Fade>
    </Layout>
  );
}