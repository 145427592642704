/**
 * 最初はダイアログで言語を選択していたが、日本語か英語しかないのでダイアログではなくボタンでの切り替え式にした。
 */

import params from './lang-parameter';
// import withGlobal from '../lib/with-global';

let langModel = undefined;

const langs = {
    'en': 'English',
    'ja': '日本語'
}

const DEFAULT_LANG = 'ja';

const exists = lang => {
    return lang in langs;
}

const LangModel = class {

    constructor(lang, setLang) {

        if (!exists(lang)) {
            lang = DEFAULT_LANG;
        }

        Object.keys(params[lang]).map((key) => {
            this[key] = params[lang][key];
            return null;
        });

        // 設定した言語で再描画
        this.setLang = setLang;
        this.lang = lang;
    }
}

/**
 * 既定の言語
 */
LangModel.defaultLang = DEFAULT_LANG;

/**
 * 言語存在チェック
 * @param {*} lang 
 */
LangModel.exists = exists;

/**
 * 言語のキーを返す
 */
LangModel.getKeys = () => {
    return Object.keys(langs);
}

/**
 * 言語の名称を返す
 * @param {string} key 
 */
LangModel.getValue = (key) => {
    return langs[key];
}

/**
 * 値を返す
 * @param {*} key 
 */
LangModel.get = key => {
    return langModel[key];
}

/**
 * 言語変更
 * @param {*} lang 
 */
LangModel.set = lang => {
    langModel.setLang(lang);
}

/**
 * @returns {string}
 */
LangModel.getAnotherLangKey = () => {
    if (langModel.lang === DEFAULT_LANG) {
        return 'en';
    } else {
        return DEFAULT_LANG;
    }  
}

/**
 * @returns {string}
 */
 LangModel.getAnotherLangValue = () => {
    if (langModel.lang === DEFAULT_LANG) {
        return langs['en'];
    } else {
        return langs[DEFAULT_LANG];
    }
}

/**
 * 引数の言語でこのクラスを設定する
 * @param {string} lang 
 */
LangModel.initiate = (lang, setLang) => {
    langModel = new LangModel(lang, setLang);
    // LangModel.globalSet('lang', langModel);
}

export default LangModel;
 