import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';

export default function Story(props) {
  const { links } = props;

  // レスポンシブ
  const matches = useMediaQuery('(min-width:600px)', { noSsr: true });
  let linksWidth;
  let linkPaddingSide;
  let linkHeight;
  let linkSpace;
  let linksOuterSpace;
  let linksOuterSide;
  let pad = '';
  if (matches) {
    linksWidth = "50vw";
    linkPaddingSide = 0;
    linkHeight = "70px";
    linkSpace = "4px";
    linksOuterSpace = "7px";
    linksOuterSide = "7px";
    pad = '22px';
  } else {
    linksWidth = "100vw";
    linkPaddingSide = "5px";
    linkHeight = "45px";
    linkSpace = "4px";
    linksOuterSpace = "4px";
    linksOuterSide = "40px";
    pad = '11px';
  }

  return (
    <>
      <Grid container alignContent={'center'}
        style={{
          backgroundColor: "rgb(17, 17, 17)",
          width: linksWidth,
          paddingTop: linksOuterSpace,
          paddingBottom: linksOuterSpace,
          paddingLeft: linksOuterSide,
          paddingRight: linksOuterSide,
        }}>
        {links.map(link => {
          return (
            <Grid item xs={6} key={link.key} style={{
              padding: linkSpace,
            }}>
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                <span
                  style={{
                    cursor: 'pointer',
                    height: linkHeight,
                    paddingTop: pad,
                    paddingBottom: pad,
                    paddingLeft: linkPaddingSide,
                    paddingRight: linkPaddingSide,
                    display: 'block',
                    textAlign: 'center',
                    width: '100%',
                    backgroundColor: 'rgb(28, 28, 28)',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'rgba(225, 225, 225, .12)',
                    borderRadius: '12px',
                  }}
                >{link.name}</span>
              </a>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}