import React from 'react';
import { Link } from 'react-router-dom'
import ViewState from '../state/view-state';

const logos = [
  { key: 'Portal', src: 'h_portal.png' },
  { key: 'Works', src: 'h_works.png' },
  { key: 'Story', src: 'h_story.png' },
  { key: 'Goods', src: 'h_goods.png' },
  { key: 'Contact', src: 'h_contact.png' }
];

const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '19px 0 19px 0',
    marginLeft: '20px',
  },
  title_container: {
    position: 'relative',
    width: '83px',
    textAlign: 'left',
    marginLeft: '5px',
  },
  title_yorunogumo: {
    display: 'block',
    objectFit: 'contain',
    height: '25px',
    fontSize: '20px',
  },
  title_visible: {
    position: 'absolute',
    display: 'block',
    objectFit: 'contain',
    width: '100%',
    height: '25px',
    fontSize: '20px',
  },
  title_invisible: {
    position: 'absolute',
    display: 'block',
    objectFit: 'contain',
    width: '100%',
    height: '25px',
    opacity: 0,
    fontSize: '20px',
  },
};

export default function Logo() {

  const [title,] = ViewState.get('title');

  return (
    <React.Fragment>
      <div style={style.container}>
        <Link to='/'>
          <span style={style.title_yorunogumo}>yorunogumo</span>
        </Link>
        <div style={style.title_container}>
          {logos.map(logo => {
            return (
              <span key={logo.key}
                style={title === logo.key ? style.title_visible : style.title_invisible}
              >{logo.key}</span>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  )
}