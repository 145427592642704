import params from './parameter';

const MailModel = class {}

MailModel.send = async data => {
  const init = {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "content-type": "application/json;charset=UTF-8",
    },
  }
  await fetch(params.url, init).then((response) => {
    // 成功
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error(response.status);
    }
  });
}

export default MailModel;
 