/**
 * 色々なコンポーネントで使用する状態を保持する。
 * ドロワーの開閉
 * ページタイトルの設定
 * など。
 */
let viewState;

const ViewState = class {
    set(key, useState) {
        this[key] = useState;
    }
}

ViewState.set = (key, useState) => {
    viewState.set(key, useState);
}

ViewState.get = key => {
    return viewState[key];
}

/**
 * ヘッダに表示されるページタイトルを設定する
 * @param {string} title 
 */
ViewState.setTitle = title => {
    const [, setTitle] = viewState.title;
    setTitle(title);
}

/**
 * カーテンを開く
 */
ViewState.openCurtain = () => {
    const [, setCurtain] = viewState.curtain;
    setCurtain(false);
}

ViewState.closeCurtain = () => {
    const [, setCurtain] = viewState.curtain;
    setCurtain(true);
}

/**
 * バージョンを返す
 */
ViewState.getVersion = () => {
    return viewState['version'];
}

/**
 * 初期化。静的でよくて、インスタンス必要ないかも
 */
(() => {
    if (!viewState) {
        viewState = new ViewState();

        // バージョン設定
        const version = document.head.querySelector('meta[name=version]')
        viewState['version'] = version ? version.content : '5';
    }
})();

export default ViewState;