import { isValid, format } from 'date-fns'
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import useFormPersist from 'react-hook-form-persist'
import useMediaQuery from '@mui/material/useMediaQuery';
import ContactSp from './ContactSp';
import ContactPc from './ContactPc';
import MailModel from '../../model/mail-model';
import SettingModel from '../../model/setting-model';
import LangModel from '../../model/lang-model';
import QsModel from '../../model/qs-model';
import ViewState from '../../state/view-state';
// import { DevTool } from "@hookform/devtools";

const DEBUG = false;
const params = DEBUG ?
  {
    required: false,
    dateValid: () => true
  } :
  {
    required: true,
    dateValid: isValid
  };

export default function Contact(props) {
  // レスポンシブ
  const matches = useMediaQuery('(min-width:600px)', { noSsr: true });

  // GETパラメーターの処理
  const qs = QsModel.createInstance(props.location.search);

  // react-hook-form
  const { register, unregister, control, handleSubmit, watch, formState: { errors }, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: true
  });

  useFormPersist("contact", {
    watch,
    setValue,
    storage: window.localStorage,
    exclude: ['limit']
  });

  // hook
  const [tab, setTab] = useState(0);
  const [limitMin, setLimitMin] = useState(null)
  const [isFreeMoney, setIsFreeMoney] = useState(false)
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [dialog, setDialog] = useState({ open: false, title: "", body: "" });
  const [selectedValueLang, setSelectedValueLang] = useState(qs.getLang());
  // const [openLang, setOpenLang] = useState(false);
  const [setting, setSetting] = useState(new SettingModel({}));

  // 言語設定
  LangModel.initiate(selectedValueLang, setSelectedValueLang);

  const atts = {
    name: {
      inputRef: {
        required: params.required,
        maxLength: { value: 50 }
      }
    },
    link: {
      inputRef: {
        required: params.required,
        maxLength: { value: 254 },
        pattern: /^http.*:\/\/.+\..+$/i
      }
    },
    company: {
      inputRef: {
        required: params.required,
        maxLength: { value: 254 }
      }
    },
    mail: {
      inputRef: {
        required: params.required,
        maxLength: { value: 254 },
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      }
    },
    hear: {
      inputRef: {
        required: params.required,
        maxLength: { value: 254 }
      }
    },
    title: {
      inputRef: {
        required: params.required,
        maxLength: { value: 80 }
      }
    },
    description: {
      inputRef: {
        required: params.required,
        maxLength: { value: 2500 }
      }
    },
    limit: {
      rules: {
        required: params.required,
        validate: value => params.dateValid(value)
      }
    },
    money: {
      rules: {
        required: params.required,
        maxLength: { value: 30 }
      },
      inputRef: {
        required: params.required,
        maxLength: { value: 1000 },
        onChange: event => {
          setIsPlaceholder(event.target.value.length === 0);
        }
      }
    }
  };

  // フォームの設定を取得
  const fetchData = () => {
    SettingModel.get({ search: props.location.search }).then(setting => {
      setLimitMin(setting.getLimitMin());
      setSetting(setting);
      ViewState.openCurtain();
    });
  };

  useEffect(fetchData, [props.location.search]);

  useEffect(() => {
    ViewState.setTitle('Contact');

    setTimeout(() => {
      ViewState.openCurtain();
    }, 3000);
  }, []);

  /**
   * 送信
   * @param {*} data 
   */
  const onSubmit = async (data, event) => {
    event.preventDefault();
    setSending(true);
    data['updatedId'] = setting.updatedId;
    data['limit'] = format(data['limit'], 'yyyy/MM/dd');

    try {
      // メール送信
      await MailModel.send(data);
      setSent(true);
      setDialog({
        open: true,
        title: LangModel.get('complete'),
        body: LangModel.get('completeMessage'),
      });
    } catch (e) {
      // 例外処理
      switch (e.message) {
        case '503':
          // メンテナンスの場合はメンテナンスページへ
          window.location.reload();
          break;

        case '403':
          // 除外ホスト
          setDialog({
            open: true,
            title: LangModel.get('black'),
            body: LangModel.get('blackMessage'),
          });
          break;

        case '409':
          // 楽観ロック
          // データが更新されている場合
          alert(LangModel.get('updated'));
          // データ再取得
          fetchData();
          // 納期をクリアする
          setValue('limit', null);
          break;

        default:
          // その他の異常
          setDialog({
            open: true,
            title: LangModel.get('sendError'),
            body: LangModel.get('sendErrorMessage'),
          });
      }
    } finally {
      setSending(false);
    }
  }

  const getButtonValue = () => {
    if (sent) {
      return LangModel.get('sent');
    }

    if (sending) {
      return LangModel.get('sending');
    } else {
      return LangModel.get('send');
    }
  }

  const isDisabled = () => {
    return sending || sent;
  }

  const handleClose = () => {
    setDialog({ open: false });
  }

  // const handleCloseLang = () => {
  //   setOpenLang(false);
  // }

  const handleTabChange = (event, value) => {
    setTab(value);
    if (value === 1) {
      setDialog({
        open: true,
        body: LangModel.get('individualMessage'),
      });
    }
  }

  const handleIsFreeMoney = () => {
    unregister("money");
    setIsFreeMoney(true);
  }

  const placeholder = isPlaceholder ? { display: 'inherit' } : { display: 'none' };

  let ContactResponsive;
  if (matches) {
    ContactResponsive = ContactPc;
  } else {
    ContactResponsive = ContactSp;
  }

  return (
    <>
      <ContactResponsive
        register={register} control={control} handleSubmit={handleSubmit} errors={errors}
        tab={tab} handleTabChange={handleTabChange}
        isFreeMoney={isFreeMoney} handleIsFreeMoney={handleIsFreeMoney}
        placeholder={placeholder}
        sending={sending} setSending={setSending}
        sent={sent} setSent={setSent}
        dialog={dialog} setDialog={setDialog}
        isDisabled={isDisabled}
        getButtonValue={getButtonValue}
        onSubmit={onSubmit}
        handleClose={handleClose}
        params={params}
        atts={atts}
        limitMin={limitMin || null}
        /*openLang={openLang} setOpenLang={setOpenLang}*/
        /*handleCloseLang={handleCloseLang}*/
        LangModel={LangModel}
        setting={setting}
      />
      {/* <DevTool control={control} /> */}
    </>
  );
}