import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Grid, Typography } from '@mui/material';

function FooterTypo(props) {
  return (
    <Typography
      variant="overline"
      component="div"
      align="center"
      style={{
        textTransform: "none",
        color: "rgb(155, 155, 155)",
      }}>
      {props.children}
    </Typography>
  );
}

export default function Footer({ loading }) {

  return (
    <Container style={{
      display: loading ? 'none' : 'block'
    }}>
      <Grid container justifyContent="center" direction="column" spacing={0}
        style={{
          paddingTop: 70,
          paddingBottom: 10
        }}>
        <Grid item>
          <FooterTypo>
            © yorunogumo.net
          </FooterTypo>
        </Grid>
        <Grid item>
          <Link to={'/terms'}>
            <FooterTypo>
              利用規約
            </FooterTypo>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}