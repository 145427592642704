import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Grid } from '@mui/material';

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    width: '100%',
    margin: '8px 0',
    textAlign: 'center',
    paddingTop: '2px !important'
  },
  typography: {
    color: 'rgb(225, 225, 225)',
    fontSize: '0.73rem',
    textAlign: 'center'
  },
});

const links = [
  // { key: 'X', url: 'https://twitter.com/yorunogumo' },
  { key: 'Bluesky', url: 'https://bsky.app/profile/yorunogumo.net' },
  { key: 'YouTube', url: 'https://www.youtube.com/playlist?list=PL_aUbA1_pMSWVgFDTlEf6n_N8aSoUgiCM' },
  { key: 'Fanbox', url: 'https://yorunogumo.fanbox.cc' },
  { key: 'Misskey', url: 'https://misskey.io/@yorunogumo' },
  { key: 'Tumblr', url: 'https://yorunogumo.tumblr.com' },
];

export default function SnsIcon() {
  const classes = useStyles();

  return (
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 75px 20px 75px',
    }}>
      <Grid
        container
        direction="column"
        spacing={2}
      >
        {links.map(link => {
          return (
            <Grid item key={link.key} className={classes.item}>
              <a href={link.url} className={classes.typography} target="_blank" rel="noopener noreferrer">
                <span>{link.key}</span>
                {/* <img
                    alt={link.key}
                    style={{display: 'block', objectFit: 'contain', width:'100%', height:'100%'}}
                    src={`${process.env.PUBLIC_URL}/${link.key}`}
                  /> */}
              </a>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}