import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { lineBreakToBr } from '../../lib/string-util';

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
  
export default function DialogComplete(props) {
  const { onClose, dialog } = props;
  const display = dialog.title ? {}: {display: 'none'};
  
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={dialog.open}>
      <DialogTitle id="simple-dialog-title" style={{fontSize: '1.05rem', ...display}}>
        {dialog.title}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {lineBreakToBr(dialog.body, (key, row) => <span key={key}>{row}<br /></span>)}
        </Typography>
      </DialogContent>
      <DialogActions>
          <Button
            autoFocus
            onClick={onClose}
            disableFocusRipple={true}
            disableRipple={true}
            color="primary">
            OK
          </Button>
        </DialogActions>
    </Dialog>
  );
}