import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LangModel from '../../model/lang-model';

const CustomTab = withStyles({
  root: {
    textTransform: "none"
  }
})(Tab);

export default function KindTab(props) {

  const { tab, onChange, disabled } = props;

  return (
    <Box>
      <Tabs value={tab} onChange={onChange}>
        <CustomTab label={LangModel.get('forCompany')} disabled={disabled} />
        <CustomTab label={LangModel.get('forIndividual')} disabled={disabled} />
      </Tabs>
    </Box>
  );
}