// npm qs、Unpacked Size:160 kB、サイズ大きすぎませんか。nodejs組み込みのモジュールを使う。
import querystring from 'querystring-es3';
import LangModel from './lang-model';

let instance = undefined;

const QsModel = class {
    constructor(value) {
        // 最初のはてなを削除
        if (value.charAt(0) === '?') {
            value = value.substring(1);
        }
        const qsObj = querystring.parse(value);
        for (const key in qsObj) {
            this[key] = qsObj[key];
        }
    }

    getLang() {
        if (LangModel.exists(this.lang)) {
            return this.lang;
        }
        return LangModel.defaultLang;
    }
}

QsModel.createInstance = value => {
    if (instance) {
        return instance;
    } else {
        instance = new QsModel(value);;
        return instance;
    }
}

export default QsModel;