import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { HamburgerSpin } from 'react-animated-burgers'
import ViewState from '../state/view-state';
import LeftDrawer from './LeftDrawer';
import Logo from './Logo';

export default function Header({ loading }) {

  const [menuOpen, setMenuOpen] = ViewState.get('menuOpen');

  return (
    <React.Fragment>
      <LeftDrawer />
      <Grid item style={{
        position: 'absolute',
        margin: '17px 10px 10px 24px',
        cursor: 'pointer',
      }}>
        <HamburgerSpin
          buttonWidth={20}
          isActive={menuOpen}
          toggleButton={() => { setMenuOpen(!menuOpen) }}
          barColor="rgb(225, 225, 225)"
        />
        {/* <MenuIcon onClick={toggleDrawer(true)} /> */}
      </Grid>
      <Grid item style={{
        textAlign: 'center',
        backgroundColor: 'rgb(17, 17, 17)',
        borderBottom: 'solid 1px rgb(125,125,125)',
      }}>
        <Logo />
      </Grid>
      <Grid item style={{
        position: 'absolute',
        top: 25,
        right: 20,
        cursor: 'pointer',
        display: loading ? 'block' : 'none'
      }}>
        <CircularProgress style={{ width: 20, height: 20, color: 'rgb(225, 225, 225)' }} />
      </Grid>
    </React.Fragment>
  )
}