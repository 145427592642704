import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Div100vh from 'react-div-100vh';
import Header from './Header';
import Footer from './Footer';
import ViewState from '../state/view-state';

export default function Layout(props) {
    const { children, loading } = props;

    useEffect(() => {
        ViewState.closeCurtain();
    }, []);

    return (
        <Div100vh style={{ display: 'flex', flexFlow: 'column' }}>
            <Header loading={loading} />
            <Box style={{ flex: 1 }}>
                {children}
            </Box>
            <Footer loading={loading} />
        </Div100vh>
    )
}