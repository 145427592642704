export const data = [
    {
        key: 'musou-no-machi',
    },
    {
        key: 'yoi-to-yukusue',
    },
    {
        key: 'kinjitsuten-ha-madatooku',
    },
];

export const dataSort = {
    'musou-no-machi': 0,
    'yoi-to-yukusue': 1,
    'kinjitsuten-ha-madatooku': 2
};

export const dataTitle = {
    'musou-no-machi': '夢想のまち',
    'yoi-to-yukusue': '酔いとゆくすえ',
    'kinjitsuten-ha-madatooku': '近日点はまだ遠く'
};
