import { addDays, isAfter, parseISO } from 'date-fns'
import params from './setting-parameter';

const SettingModel = class {
  
  constructor(json) {
    this.limitMinAdd = json['LIMIT_MIN_ADD'];
    this.limitMin = json['LIMIT_MIN'];
    this.money = json['MONEY'];
    this.noticeTitle = json['NOTICE_TITLE'];
    this.notice = json['NOTICE'];
    this.maintenance = json['MAINTENANCE'];
    this.updatedId = json['UPDATED_ID'];
  }

  isNotice() {
    return this.notice.trim().length > 0;
  }

  isLoaded() {
    return this.updatedId !== undefined;
  }

  /**
   * 相対か絶対かで遠い日付を採用する
   */
  getLimitMin() {
    const limitMinAdded = addDays(new Date(), this.limitMinAdd);
    const limitMin = parseISO(this.limitMin);
    if (isAfter(limitMin, limitMinAdded)) {
      return limitMin;
    } else {
      return limitMinAdded;
    }
  }
}

SettingModel.get = async ({search}) => {
  const init = {
    method: "GET",
    cache: "no-store",
    headers: {
      "content-type": "application/json;charset=UTF-8",
    },
  }

  try {
    const response = await fetch(params.getUrl + search, init);
    const result = await response.text();
    return new SettingModel(JSON.parse(result));
  } catch(e) {
    return new SettingModel({});
  }
}

export default SettingModel;
 