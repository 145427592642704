/**
 * 改行されないテキストにBR入れたりするのに使う。
 * 改行コードを配列にして注入された関数を適用する。
 * 
 * @param {*} text 変換対象の文字列
 * @param {*} toJsx JSXを返す関数
 */
export const lineBreakToBr = (text, toJsx) => {
    if (!text) return;
    
    return text.split("\n").map((row, key) => {
        return toJsx(key, row);
    });
}
