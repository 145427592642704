import { useState } from 'react';

const links = [
    [
        { key: 'merc_amazon', name: 'Amazon', url: 'https://www.amazon.co.jp/dp/4832238094/' },
        { key: 'merc_honto', name: 'honto', url: 'https://honto.jp/ebook/pd_30831869.html' },
        { key: 'merc_rakutenbooks', name: 'Rakutenブックス', url: 'https://books.rakuten.co.jp/rb/16601067/' },
        { key: 'merc_bookwalker', name: 'BOOK WALKER', url: 'https://bookwalker.jp/de725a6a3c-ec2e-4025-bcd2-012be748fb72/' },
        { key: 'merc_kinokuniyashoten', name: '紀伊國屋書店', url: 'https://www.kinokuniya.co.jp/f/dsg-01-9784832238091' },
        { key: 'merc_unext', name: 'U-NEXT', url: 'https://www.video.unext.jp/book_title/BSD0000430670/BID0000723727' },
        { key: 'merc_ebookjapan', name: 'eBookJapan', url: 'https://ebookjapan.yahoo.co.jp/books/626896/A002536551/' },
        { key: 'merc_glacierbaybooks', name: 'Glacier Bay Books', url: 'https://glacierbaybooks.com/product/the-other-side-of-the-clouds-volume-1/' }
    ],
    [
        { key: 'merc_amazon', name: 'Amazon', url: 'https://www.amazon.co.jp/dp/4046826037' },
        { key: 'merc_honto', name: 'honto', url: 'https://honto.jp/ebook/pd_32720012.html' },
        { key: 'merc_rakutenbooks', name: 'Rakutenブックス', url: 'https://books.rakuten.co.jp/rb/17557543/' },
        { key: 'merc_bookwalker', name: 'BOOK WALKER', url: 'https://bookwalker.jp/deca8d9794-4cb5-4015-8a6e-b725e455666b/' },
        { key: 'merc_kinokuniyashoten', name: '紀伊國屋書店', url: 'https://www.kinokuniya.co.jp/f/dsg-01-9784046826039' },
        { key: 'merc_unext', name: 'U-NEXT', url: 'https://www.video.unext.jp/book_title/BSD0000701011/BID0001196065' },
        { key: 'merc_ebookjapan', name: 'eBookJapan', url: 'https://ebookjapan.yahoo.co.jp/books/779914/' },
        { key: 'merc_kadokawa', name: 'カドカワストア', url: 'https://store.kadokawa.co.jp/shop/g/g322303000659' }
    ],
    [
        { key: 'merc_amazon', name: 'Amazon', url: 'https://www.amazon.co.jp/dp/B0CWGSVJWK' },
        { key: 'merc_honto', name: 'honto', url: 'https://honto.jp/netstore/pd-magazine_33327396.html' },
        { key: 'merc_rakutenbooks', name: 'Rakutenブックス', url: 'https://books.rakuten.co.jp/rb/17845686/' },
        { key: 'merc_bookwalker', name: 'BOOK WALKER', url: 'https://bookwalker.jp/dec4f38249-a5fb-4bfb-a592-3ba9fa08390c/' },
        { key: 'merc_kinokuniyashoten', name: '紀伊國屋書店', url: 'https://www.kinokuniya.co.jp/f/dsg-04-4910137390645' },
        { key: 'merc_unext', name: 'U-NEXT', url: 'https://video-share.unext.jp/book/title/BSD0000169651/BID0001430139' },
        { key: 'merc_ebookjapan', name: 'eBookJapan', url: 'https://ebookjapan.yahoo.co.jp/books/387087/A005037591/' }
    ]
];


function useData() {
    const [data] = useState(links);

    return [data];
}

export default useData;