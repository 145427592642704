import { useState, forwardRef } from 'react';
import Slider from "react-slick";
import Shop from './Shop';
import useData from './useData';

function ShopList(props, refSlider) {
    const { refParentSlider } = props;
    const [data] = useData();
    const [showIndex, setShowIndex] = useState([true, true, true]); // TODO: 3作品前提。動的にしよう。

    const settings = {
        fade: true,
        beforeChange: (oldIndex, newIndex) => {
            setShowIndex([true, true, true]);
            refParentSlider.current.slickGoTo(newIndex);
        },
        afterChange: (index) => {
            const nextShowIndex = [false, false, false];
            nextShowIndex[index] = true;
            setShowIndex(nextShowIndex);
        }
    };

    return (
        <>
            <Slider ref={refSlider} {...settings}>
                {data.map((links, index) => {
                    return (
                        <div key={index}>
                            <div style={{
                                width: '100vw',
                                display: (showIndex[index]) ? 'flex' : 'none',
                                justifyContent: 'center'
                            }}>
                                <Shop links={links}></Shop>
                            </div>
                        </div>
                    )
                })}
            </Slider >
        </>
    )
}

export default forwardRef(ShopList);