import React, { useEffect, useState } from 'react';
import { Grid, Box, Fade } from '@mui/material';
import SnsIcon from './SnsIcon';
// import News from './News';
import ViewState from '../../state/view-state';
import Layout from '../Layout';
import Picture from '../component/Picture';

function Top() {

  const [fade, setFade] = useState(false);

  // 親コンポーネントの状態を更新する場合、useEffect内で更新しないとwarningが出る。
  useEffect(() => {
    ViewState.setTitle('Portal');

    setTimeout(() => {
      ViewState.openCurtain();
    }, 3000);
  }, []);

  return (
    <Layout loading={!fade}>
      <Fade in={fade} timeout={700}>
        <Box>
          <Grid
            container
            direction="column"
            spacing={0}
          >
            <Grid item style={{}}>
              <Picture
                name="top"
                style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%' }}
                onLoad={() => {
                  setTimeout(() => {
                    ViewState.openCurtain();
                    setFade(true);
                  }, 10);
                }}
              />
            </Grid>
            <Grid item style={{ margin: '30px 0 30px 0' }}>
              <SnsIcon />
            </Grid>
            {/* <Grid item style={{textAlign: 'center', margin: '20px 0 0 0'}}>
              <Typography style={{fontSize: '1.2rem'}}>NEWS</Typography>
            </Grid>
            <Grid item style={{}}>
              <News />
            </Grid> */}
          </Grid>
        </Box>
      </Fade>
    </Layout>
  )
}

export default Top;