import React from 'react';
import { Drawer } from '@mui/material';
import Menu from './Menu';
import ViewState from '../state/view-state';
import ViewEvent from '../event/view-event';

export default function LeftDrawer() {
  
  const [menuOpen, setMenuOpen] = ViewState.get('menuOpen');
  const toggleDrawer = ViewEvent.get('toggleDrawer');

  return (
    <Drawer
      anchor={'left'}
      open={menuOpen}
      onClose={toggleDrawer(false)}
      transitionDuration={0}
    >
      <Menu onClick={toggleDrawer} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </Drawer>
  )
}