let viewEvent;

const ViewEvent = class {

    set(key, event){
        this[key] = event;
    }
}

ViewEvent.set = (key, event) => {
    viewEvent.set(key, event);
}

ViewEvent.get = key => {
    return viewEvent[key];
}

(() => {
    if (!viewEvent) {
        viewEvent = new ViewEvent();
    }    
})();

export default ViewEvent;
 