import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import LangModel from '../../model/lang-model';

export default function OrderStep(props) {

  const [open, setOpen] = useState(false);
  const steps = LangModel.get('orderStep');
  let height;
  let ExpandIcon;
  let carten = null;
  let anchor = null;
  if (open) {
    height = "auto";
    ExpandIcon = ExpandLessSharpIcon;
  } else {
    height = "137px";
    ExpandIcon = ExpandMoreIcon;
    anchor = "#root";
    carten = (
      <Box
        onClick={() => { document.getElementById('OrderStep-expand').click() }}
        style={{
          position: "absolute",
          top: "-30px",
          height: "33px",
          width: "100%",
          background: "linear-gradient(to bottom, rgba(17, 17, 17, 0.3), rgb(17, 17, 17))"
        }}
      ></Box>
    );
  }

  return (
    <Box>
      <Box sx={{ maxWidth: 600, height: height, overflow: "hidden" }}>
        <Box sx={{ mb: 2.7 }}>
          <Typography variant="subtitle1" style={{ textAlign: props.titleAlign }}>{LangModel.get('orderStepTitle')}</Typography>
        </Box>
        <Stepper orientation="vertical">
          {steps.map((step) => (
            <Step key={step.label} active={true}>
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography variant="body2">{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box style={{ position: "relative" }}>
        {carten}
        <a
          id="OrderStep-expand"
          href={anchor}
          onClick={() => {
            setOpen(!open)
          }}
          style={{
            textDecoration: "none",
            color: "gray",
            display: "inline-block",
            cursor: "pointer",
            textAlign: "center",
            width: "100%",
            marginTop: "15px",
          }}>
          <ExpandIcon />
        </a>
      </Box>
    </Box>
  );
}