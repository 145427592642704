/**
 * TODO キャンバスにボトムボーダーは無しで、左端、右端に近づく程に文字色を背景色に近づけて見えなくする
 * TODO クリックで選択
 * TODO worksの内容をスワイプに合わせて切り替える
 * TODO スマホイベント対応
 * TODO コントロール外でのスワイプも横スクロールできるようにしたい？ドラッグ中だけは反応するとか
 * TODO Storyのお店ボタンと同じデザインにするか
 */
import YearItem from './YearItem';

const posY = 60;
const posDiffX = 120;
const scrollSpeed = 2;
const font = '1.8rem "Helvetica", "Arial", sans-serif';
const selectedColor = 'rgb(225, 225, 225)';
const unselectedColor = 'rgb(155, 155, 155)';

class YearItemGroup {

    constructor(setSelectedYear, canvasRef, itemList, selectedYearItem) {
        this.setSelectedYear = setSelectedYear;
        this.canvasRef = canvasRef;
        this.canvas = null;
        this.ctx = null;
        this.itemList = itemList;
        this.selectedYearItem = selectedYearItem;
        this.textWidth = 0;
        this.canvasCenterX = 0;
        this.canvasTextStartX = 0;
    }

    //
    getCanvas() {
        if (!this.canvas) {
            this.canvas = this.canvasRef.current;
            this.ctx = this.canvas.getContext('2d');
        }

        return [this.canvas, this.ctx];
    }

    getNearItem(targetX) {
        let minDiffItem = null;
        let minDiffX = Number.MAX_SAFE_INTEGER;

        for (const item of this.itemList) {
            const diffX = Math.abs(targetX - item.x);
            if (minDiffX > diffX) {
                minDiffX = diffX;
                minDiffItem = item;
            }
        }

        return minDiffItem;
    }

    clear() {
        const [canvas, ctx] = this.getCanvas();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

    }

    renderAllItem(addX) {
        const [canvas, ctx] = this.getCanvas();

        /* 補助線 */
        // for (let i = 0; i < 8; i++) {
        //     const p = i * 100
        //     ctx.fillText(p, p, 25);
        // }
        // ctx.fillText(Math.round(this.canvasTextStartX), this.canvasTextStartX, 80);
        /* 補助線 */

        const centerItem = this.getNearItem(this.canvasTextStartX);
        this.setSelectedYear(centerItem.year);

        for (const item of this.itemList) {
            let color = unselectedColor;
            if (centerItem === item) {
                color = selectedColor;
            }
            item.setX(this.canvasTextStartX + getX(item.year, this.selectedYearItem.year) + addX);
            item.render(canvas, ctx, color);
        }
    }

    // スワイプによる描画
    renderScroll(scrollX) {
        const [canvas, ctx] = this.getCanvas();
        const addX = scrollX * scrollSpeed;

        // テキストの横幅を取得する
        this.textWidth = (() => {
            ctx.fillText(this.selectedYearItem.year, 0, 0);
            ctx.font = font;

            return ctx.measureText(this.selectedYearItem.year).width;
        })();

        // 中心x座標を取得
        this.canvasCenterX = canvas.width / 2;
        this.canvasTextStartX = this.canvasCenterX - this.textWidth / 2;

        // クリア
        this.clear(canvas, ctx);

        // 描画
        this.renderAllItem(addX);
    }

    // 指を離した後の自動で位置を合わせる描画（定速でアイテムを移動）
    renderJustify() {
        const [canvas, ctx] = this.getCanvas();

        // 選択されるアイテムを特定する
        this.selectedYearItem = this.getNearItem(this.canvasTextStartX);

        // クリア
        this.clear(canvas, ctx);

        // 描画
        this.renderAllItem(0);

        // アニメーションしなければいらない
        // 選択されるアイテムが中央になるxと、現在のxとの差を取得する
        // const diffFromCenterX = this.canvasTextStartX - this.selectedYearItem.x;
        // this.move(diffFromCenterX);
    }

    // タップした時にタップしたアイテムを中心に合わせる描画（定速でアイテムを移動）
    renderStaticPosition(targetX) {
        const [canvas, ctx] = this.getCanvas();

        // 選択されるアイテムを特定する
        this.selectedYearItem = this.getNearItem(targetX);

        // クリア
        this.clear(canvas, ctx);

        // 描画
        this.renderAllItem(0);
    }

    // 引数の年を中心に移動する
    // move(addX) {
    //     const [canvas, ctx] = this.getCanvas();

    //     let x = 0;
    //     let startTime = null; // アニメーション開始時刻
    //     const duration = 100; // アニメーションの所要時間（ミリ秒）

    //     function easeInQuad(t, acceleration) {
    //         return Math.pow(t, acceleration);
    //     }

    //     const draw = (timestamp) => {
    //         if (!startTime) {
    //             startTime = timestamp;
    //         }

    //         // 経過時間の割合を計算
    //         let progress = (timestamp - startTime) / duration;
    //         if (progress > 1) {
    //             progress = 1;
    //         }

    //         progress = easeInQuad(progress, 1.5);
    //         x = addX * progress;

    //         // 描画
    //         this.clear();
    //         this.renderAllItem(x);

    //         // アニメーションが完了するまで繰り返し
    //         if (progress < 1) {
    //             requestAnimationFrame(draw);
    //         }
    //     }

    //     requestAnimationFrame(draw);
    // }

    static createInstanceBetween = (setSelectedYear, canvasRef, yearFrom, yearTo) => {
        const itemList = [];
        let position = 1;
        let yearItem = null;

        for (let year = yearFrom; year <= yearTo; year++) {
            yearItem = YearItem.createInstance(
                position,
                year,
                0,
                getY(position));

            itemList.push(yearItem);
            position++;
        }

        return new YearItemGroup(setSelectedYear, canvasRef, itemList, yearItem);
    }
}

const getX = (selfYear, selectedYear) => {
    const diffYear = selectedYear - selfYear;

    return (diffYear * posDiffX) * -1;
}

const getY = () => {
    return posY;
}

export default YearItemGroup;