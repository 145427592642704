import React from 'react';
import { addDays } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import LangModel from '../../model/lang-model';

export default function DatePickerLimit(props) {
  const { ...other } = props;
  const locale = LangModel.get('dateLocal');
  const i18nText = {
    components: {
      MuiLocalizationProvider: {
        defaultProps: {
            localeText: {
                previousMonth: '前月',
                nextMonth: '次月',
                openPreviousView: 'open previous view',
                openNextView: 'open next view',
                cancelButtonLabel: LangModel.get('cancel'),
                clearButtonLabel: 'クリア',
                okButtonLabel: LangModel.get('ok'),
                todayButtonLabel: '本日',
                start: '開始',
                end: '終了',
            }
        }
      }
    }
  }

  const defaultFormats = {
    dayOfMonth: "d",
    fullDate: "PP",
    fullDateWithWeekday: "PPPP",
    fullDateTime: "PP p",
    fullDateTime12h: "PP hh:mm aaa",
    fullDateTime24h: "PP HH:mm",
    fullTime: "p",
    fullTime12h: "hh:mm aaa",
    fullTime24h: "HH:mm",
    hours12h: "hh",
    hours24h: "HH",
    keyboardDate: "P",
    keyboardDateTime: "P p",
    keyboardDateTime12h: "P hh:mm aaa",
    keyboardDateTime24h: "P HH:mm",
    minutes: "mm",
    month: "LLLL",
    monthAndDate: "MMMM d",
    monthAndYear: LangModel.get('monthAndYear'), // ここだけ使っている
    monthShort: "MMM",
    weekday: "EEEE",
    weekdayShort: "EEE",
    normalDate: "d MMMM",
    normalDateWithWeekday: "EEE, MMM d",
    seconds: "ss",
    shortDate: "MMM d",
    year: "yyyy",
  };
  
  const getSelectableDate = () => {
    return addDays(new Date(), 14);
  }

  const minDate = props.minDate? props.minDate: getSelectableDate();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={i18nText.components.MuiLocalizationProvider.defaultProps.localeText}
      dateFormats={defaultFormats}
    >
      <MobileDatePicker
        {...other}
        renderInput={(props) => 
          <TextField
            {...props}
            fullWidth
            focused={other.focused}
            variant={other.variant}
            InputLabelProps={{ shrink: other.shrink }}
            error={other.error}
          />
        }
        componentsProps={{ 
          actionBar: { 
            actions: ['cancel', 'accept']
          }
        }}
        views={['day']}
        showToolbar={false}
        disableHighlightToday={true}
        defaultCalendarMonth={minDate}
        disablePast={true}
        reduceAnimations={true}
        invalidDateMessage=""
        minDateMessage=""
        inputFormat={LangModel.get('dateFormat')}
        margin="normal"
        toolbarFormat={LangModel.get('toolbarFormat')}
        toolbarPlaceholder=""
      />
    </LocalizationProvider>
  );
}