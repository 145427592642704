import React, { useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';
import ViewState from '../../state/view-state';
import Layout from '../Layout';

// function TermsLink(props) {
//   return (
//     <Link to={props.to} style={{
//       textDecoration: 'underline',
//       color: 'rgba(0, 0, 0, 0.87)'
//     }}>
//       {props.children}
//     </Link>
//   )
// }

export default function Terms() {

  useEffect(() => {
    ViewState.setTitle('Portal');
    ViewState.openCurtain();
  }, []);

  return (
    <Layout>
      <Container maxWidth="sm" style={{ marginTop: 50 }}>
        <Box mt={5}>
          <Typography variant="h6" gutterBottom={true} style={{ fontWeight: 'normal' }}>Terms of service</Typography>
          <Typography variant="body1">当サイトに掲載している画像は合同会社帆白工房が権利を有しており、転載・無断使用は禁止です。</Typography>
        </Box>
        <Box mt={5}>
          <Typography variant="h6" gutterBottom={true} style={{ fontWeight: 'normal' }}>プライバシーポリシー</Typography>
          <Typography variant="body1">当サイトが受け取る情報はご依頼を遂行するために利用します。第三者提供は行いません。</Typography>
        </Box>
        <Box mt={5}>
          <Typography variant="h6" gutterBottom={true} style={{ fontWeight: 'normal' }}>クッキーポリシー</Typography>
          <Typography variant="body1">当サイトはアクセス制御に利用するファーストパーティークッキーを発行します。サードパーティークッキーは発行されません。</Typography>
        </Box>
      </Container>
    </Layout>
  )
}