/**
 * windowオブジェクトを扱うクラスを返す高階関数
 * @param {*} klass 
 */
const withGlobal = (klass, v) => {
    
    klass.globalSet = (key, value) => {
        window.yorunogumo[key] = value;
    }

    klass.globalGet = (key) => {
        return window.yorunogumo[key];
    }

    return klass;
}

withGlobal.initiate = () => {
    window.yorunogumo = {};
}

export default withGlobal;