import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto';
import App from './App';
import * as serviceWorker from './serviceWorker';
import withGlobal from './lib/with-global';
import "./index.css"

/**
 * グローバル変数（windowオブジェクト）初期化
 */
(() => {
  withGlobal.initiate();
})();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.unregister();
