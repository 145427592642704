import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Grid, Box, Fade, Typography } from '@mui/material';
import ViewState from '../../state/view-state';
import KvModel from '../../model/kv-model';
import Layout from '../Layout';
import YearList from './year_list'
import { lineBreakToBr } from '../../lib/string-util';

const useStyles = makeStyles({
  typography: {
    fontSize: '0.90rem',
  },
});

const currentYear = (new Date()).getFullYear();

/**
 * 年の幅を取得する
 * @param {*} json 
 * @returns 
 */
const getYear = (json) => {
  let minYear = 2050;
  let maxYear = 2017;


  json.forEach(work => {
    const year = parseInt(work.title.slice(0, 4));
    if (2017 < year && year < 2050) {
      if (year < minYear) {
        minYear = year;
      }

      if (maxYear < year) {
        maxYear = year;
      }
    }
  });

  if (minYear < maxYear) {
    return [minYear, maxYear];
  } else {
    return [currentYear, currentYear];
  }
}

export default function Works() {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [fade, setFade] = useState(false);
  const [works, setWorks] = useState([]);
  const [minYear, setMinYear] = useState(currentYear);
  const [maxYear, setMaxYear] = useState(currentYear);

  // TODO 年ごとのworksを追加し、既存のworksを削除
  const [selectedYear, setSelectedYear] = useState(0);
  const [worksPerYear, setWorksPerYear] = useState({});

  useEffect(() => {
    ViewState.setTitle('Works');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // リモートよりデータを取得する
      const result = await KvModel.get('works');
      const json = JSON.parse(result);

      // 最小と最大の年を取得する
      const [initMinYear, initMaxYear] = getYear(json);
      setMinYear(initMinYear);
      setMaxYear(initMaxYear);

      // 最初は最大の年を選択する
      setSelectedYear(initMaxYear);

      // 年ごとにグループ化する
      const worksPerYear = (() => {
        const jsonPerYear = {};

        for (const row of json) {
          const rowYear = row.title.slice(0, 4);
          if (!(rowYear in jsonPerYear)) {
            jsonPerYear[rowYear] = [];
          }
          jsonPerYear[rowYear].push(row);
        }

        return jsonPerYear;
      })();

      setWorksPerYear(worksPerYear);
      setWorks(worksPerYear[initMaxYear]);
      setStep(2);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (step === 2) {
      ViewState.openCurtain();
      setFade(true);
    }
  }, [step]);

  useEffect(() => {
    if (selectedYear in worksPerYear) {
      setWorks(worksPerYear[selectedYear]);
    } else {
      setWorks([]);
    }
  }, [selectedYear, worksPerYear]);

  return (
    <Layout loading={!fade}>
      <Fade in={works.length > 0} timeout={700}>
        <Box>
          <Grid container justifyContent="center" style={{ marginTop: "23px" }}>
            <YearList setSelectedYear={setSelectedYear} minYear={minYear} maxYear={maxYear} />
          </Grid>
          <Container maxWidth="sm" style={{ marginTop: 22 }}>
            <Grid container direction="column" spacing={3}>
              {works.map((card, index) => {
                return (
                  <Grid item key={index}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className={classes.typography}>{card.title}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.typography}>
                          {lineBreakToBr(card.text, (key, row) => <span key={key}>{row}<br /></span>)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Container>
        </Box>
      </Fade>
    </Layout>
  )
}