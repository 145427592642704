import React from 'react';
import ViewState from '../state/view-state';

const style = {
  container: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100vh - 64px)',
    marginTop: '64px', // 64pxはヘッダの高さ
    backgroundColor: 'rgb(17, 17, 17)',
    zIndex: 10,
  },
};

export default function Curtain() {

  const [curtain,] = ViewState.get('curtain');
  const display = curtain ? 'block' : 'none';

  return (
    <div style={{ display: display, ...style.container }} />
  )
}