/**
 */
const KvModel = class {}

KvModel.get = async key => {
  const init = {
    method: "GET",
  }
  
  try {
    const response = await fetch(`${process.env.REACT_APP_FIND_URL}?key=${key}`, init);
    const text = await response.text();
    return text;

  } catch(e) {
    return null;
  }
}

export default KvModel;