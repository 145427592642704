import { useState, useEffect, useRef } from 'react';
import YearItemGroup from './YearItemGroup';

const scale = 2;

const style = {
    container: {
        maxWidth: '535px',
        width: '90vw',
        height: '50px',
        backgroundColor: 'rgb(28, 28, 28)',
        borderRadius: '20px',
    }
};

const resizeCanvas = (canvas) => {
    // キャンバスサイズを画面表示サイズより大きくして高精細にする
    const cssWidth = getComputedStyle(canvas).getPropertyValue('width');
    const cssHeight = getComputedStyle(canvas).getPropertyValue('height');

    canvas.width = parseInt(cssWidth) * scale;
    canvas.height = parseInt(cssHeight) * scale;
}

const getClientX = (event) => {
    return event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
}

function YearList(props) {

    const canvasRef = useRef(null);
    const isDragging = useRef(false);
    const isMouseDown = useRef(false);
    const moveDistance = useRef(0);
    const { setSelectedYear, minYear, maxYear } = props;
    const [startX, setStartX] = useState(0);
    const [scrollX, setScrollX] = useState(0);
    const [yearItemGroup, setYearItemGroup] = useState(YearItemGroup.createInstanceBetween(setSelectedYear, canvasRef, minYear, maxYear));

    useEffect(() => {
        const canvas = canvasRef.current;
        resizeCanvas(canvas);
    }, []);

    // イベントリスナー設定
    useEffect(() => {
        const resize = () => {
            const canvas = canvasRef.current;
            resizeCanvas(canvas);
            yearItemGroup.renderScroll(0);
            yearItemGroup.renderJustify();
        }

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [yearItemGroup]);

    // スマートフォンで横スワイプで画面全体が横に少し動いて戻る挙動を止める
    useEffect(() => {
        const canvas = canvasRef.current;

        const handleTouchStart = (event) => {
            event.preventDefault();
        };

        const handleTouchMove = (event) => {
            event.preventDefault();
        };

        canvas.addEventListener('touchstart', handleTouchStart, { passive: false });
        canvas.addEventListener('touchmove', handleTouchMove, { passive: false });

        // クリーンアップ関数
        return () => {
            // コンポーネントがアンマウントされた時にイベントリスナーを削除
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    useEffect(() => {
        setYearItemGroup(YearItemGroup.createInstanceBetween(setSelectedYear, canvasRef, minYear, maxYear));
    }, [minYear, maxYear, setSelectedYear]);

    // 初期描画
    useEffect(() => {
        yearItemGroup.renderScroll(0);
        yearItemGroup.renderJustify();
    }, [yearItemGroup]);

    useEffect(() => {
        yearItemGroup.renderScroll(scrollX);
    }, [scrollX, yearItemGroup]);

    const handleClick = (event) => {
        if (isDragging.current === true) {
            event.preventDefault();
        } else {
            const canvas = canvasRef.current;
            const rect = canvas.getBoundingClientRect();
            const clientX = getClientX(event);
            const mouseX = (clientX - rect.left) * scale;

            // console.log(`Clicked at (${mouseX}), ${clientX}`);

            yearItemGroup.renderStaticPosition(mouseX);
        }
    }

    const handleMouseDown = (event) => {
        const clientX = getClientX(event);
        isMouseDown.current = true;
        moveDistance.current = clientX;
        setStartX(clientX - scrollX);
    };

    const handleMouseMove = (event) => {
        if (isMouseDown.current === false) return;

        const clientX = getClientX(event);
        const x = clientX - startX;
        isDragging.current = true;
        setScrollX(x);
    };

    const handleMouseUp = (event) => {
        // 押下中以外は何も起こらない
        if (isMouseDown.current === false) return;

        if (isDragging.current === false) {
            handleClick(event);
        } else {
            const clientX = getClientX(event);
            if ((moveDistance.current - clientX) === 0) {
                // 移動距離が0の場合もドラッグしてない判定 = クリック判定とする
                isDragging.current = false;
                handleClick(event);
            }
        }

        isDragging.current = false;
        isMouseDown.current = false;

        yearItemGroup.renderJustify();
        setStartX(0);
        setScrollX(0);
    };

    return (
        <>
            <canvas
                ref={canvasRef}
                style={{ ...style.container }}
                // onClick={handleClick}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleMouseUp}
                onTouchCancel={handleMouseUp}
            >
            </canvas>
        </>
    );
}

export default YearList;