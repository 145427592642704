const disappearColor = 'rgb(28, 28, 28)';

class YearItem {

    constructor(position, year, x, y) {
        this.position = position;
        this.year = year;
        this.x = x;
        this.y = y;
    }

    setX(x) {
        this.x = x;
    }

    render(canvas, ctx, color) {
        // グラデーションの開始点と終了点を設定
        const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
        // グラデーションの色を追加
        gradient.addColorStop(0.05, disappearColor); // グラデーションの開始色
        gradient.addColorStop(0.3, color);
        gradient.addColorStop(0.7, color);
        gradient.addColorStop(0.95, disappearColor); // グラデーションの終了色

        // グラデーションをテキストの塗りつぶしスタイルとして設定
        ctx.fillStyle = gradient;
        // ctx.fillStyle = color;
        ctx.fillText(this.year, this.x, this.y);
    }

    static createInstance = (position, year, x, y) => {
        return new YearItem(position, year, x, y);
    }
}

export default YearItem;