import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import globalStyles from './style/globalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from "@mui/material";
import StylesProvider from '@mui/styles/StylesProvider';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import "./index.css";
import ViewState from './state/view-state';
import ViewEvent from './event/view-event';
import ScrollToTop from './view/ScrollToTop';
import Curtain from './view/Curtain';
import Portal from './view/portal/Portal';
import Works from './view/works/index';
import Goods from './view/simple/Goods';
import Terms from './view/simple/Terms';
import Contact from './view/contact/Contact';
import Story from './view/story/index';

export default function App() {

  // レスポンシブ
  const matches = useMediaQuery('(min-width:600px)', { noSsr: true });

  // テーマ
  const theme = createTheme({
    components: {
      SnsIcon: {
        styleOverrides: {
          container: {
            width: matches ? '100%' : '100%',
            justifyContent: 'space-between'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              color: 'rgb(225, 225, 225)', // 入力テキストカラー
            },
            '& .MuiInputLabel-root': {
              color: 'rgb(155, 155, 155)', // ラベルカラー
            },
            // filled
            '& .MuiFilledInput-root': {
              backgroundColor: 'rgb(28, 28, 28)', // 背景カラー
              '&.Mui-focused': {
                backgroundColor: 'rgb(28, 28, 28)',
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // rootキーはButtonのルート（最外層）要素に対するスタイル
          root: {
            color: 'rgb(225, 225, 225)',
            backgroundColor: 'rgb(17, 17, 17)',
            border: '1px solid rgb(155, 155, 155)',
            '&:hover': {
              backgroundColor: 'rgb(28, 28, 28)',
              border: '1px solid rgb(155, 155, 155)',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'rgb(155, 155, 155)', // ラベルカラー
            '&.Mui-focused': {
              color: 'rgb(155, 155, 155)',
            },
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            color: 'rgb(225, 225, 225)', // 入力テキストカラー
          },
        }
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            // 通常状態の下線の色
            '&:before': {
              borderBottom: '1px solid rgb(155, 155, 155)',
            },
            // フォーカス状態の下線の色
            '&:after': {
              borderBottom: '2px solid rgb(155, 155, 155)',
            },
            // マウスホバー状態の下線の色
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: '2px solid rgb(155, 155, 155)',
            },
          }
        }
      },
      // SP用
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '1px solid rgb(155, 155, 155)',
            },
            '&:after': {
              borderBottom: '1px solid rgb(155, 155, 155)',
            },
            '&.Mui-focused': {
              backgroundColor: 'rgb(28, 28, 28)',
            },
          },
        },
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: 'rgb(89,112,133)'
      }
    },
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    transitions: {
      // So we have `transition: none;` everywhere
      // create: () => 'none',
    },
    typography: {
      "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
      "fontSize": 13,
    }
  });

  // クラスにしたい
  // if (!matches) {
  //   delete theme.overrides.SnsIcon.container.width;
  // }

  /**
   * 共通の状態
   */
  const [menuOpen, setMenuOpen] = useState(false);
  // ドロワー開閉
  ViewState.set('menuOpen', [menuOpen, setMenuOpen]);
  // タイトル
  ViewState.set('title', useState(false));
  // カーテン
  ViewState.set('curtain', useState(true));
  // setTimeout(() => {
  //   // 最大5秒でオープンする
  //   ViewState.openCurtain();
  // }, 5000);
  // ドロワー開閉イベント
  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenuOpen(open);
  };
  ViewEvent.set('toggleDrawer', toggleDrawer);

  return (
    <React.Fragment>
      <CssBaseline />
      {globalStyles}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <Router>
              <ScrollToTop />
              <Curtain />
              <Switch>
                <Route exact path="/" component={Portal} />
                <Route exact path="/works" component={Works} />
                <Route path="/story/:title?" component={Story} />
                <Route exact path="/goods" component={Goods} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/contact" component={Contact} />
              </Switch>
            </Router>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
}